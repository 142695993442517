import React, { useEffect, useRef, useState } from 'react';
import './ProductPage.css';
import './Header.css';
import NavBar from './NavBar';
import HeaderAnimation from './HeaderAnimation';

const Header = (props) => {
  const { pageHeading, rightContent } = props;
  const divRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (divRef.current) {
      const children = divRef.current.children;
      const textHeight = children[0].offsetHeight;
      let newHeight = textHeight < 75 ? 75 : textHeight;

      setContentHeight(newHeight);
    }
  }, []);

  return (
    <>
      <div className="hero-image">
        <NavBar />
        <div className="hero-content-split">
          <div
            className="title-box"
            ref={divRef}
            style={{ position: 'relative', height: `${contentHeight}px` }}
          >
            <h1 className="page-title">{pageHeading}</h1>
            <h1 className="page-title title2">{pageHeading}</h1>
          </div>
          {window.innerWidth >= 710 ? (
            <div className="hero-rside-box" style={{ marginTop: 'auto' }}>
              {rightContent
                ? React.cloneElement(rightContent, {
                    contentHeight,
                    setContentHeight,
                  })
                : null}
            </div>
          ) : null}
        </div>
        <HeaderAnimation />
      </div>

      {window.innerWidth < 710 && rightContent ? (
        <div className="hero-rside-box">{rightContent}</div>
      ) : null}
    </>
  );
};

export default Header;

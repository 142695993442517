import React, { useEffect, useRef,useState } from 'react';
import computerScreenImage from './computer-screen.png';
import './LearnFastProductCard.css';

export default function LearnFastProductCard() {
  const productCardContainerRef = useRef(null);
  const headerImageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    adjustTitleBoxHeight();
    window.addEventListener('resize', adjustTitleBoxHeight);
    return () => {
      window.removeEventListener('resize', adjustTitleBoxHeight);
    };
  }, [imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const adjustTitleBoxHeight = () => {
    const productCardContainer = productCardContainerRef.current;
    const headerImage = headerImageRef.current;

    if (productCardContainer && headerImage && imageLoaded) {
      // Reset the height of the title box
      productCardContainer.style.height = 'auto';

      // Set the height of the title box to the height of the image
      const imageHeight = headerImage.offsetHeight;
      const viewportWidth = window.innerWidth;
      const scalingFactor = viewportWidth > 1055? Math.pow(viewportWidth / 1300, 0.05) : Math.pow(viewportWidth / 1300, 0.1);

    // Decrease the height of the title box relative to the image height and scaling factor
      const adjustedHeight = imageHeight * scalingFactor;
      productCardContainer.style.height = `${adjustedHeight-20}px`;
    }
  };
  function redirectToURL() {
    const url = 'https://chrome.google.com/webstore/detail/learnfast/edakpcmkahenpalocbmibgpppajlnpdb';
    window.open(url, '_blank');
  }

  return (
    <div className="product-card-container" ref={productCardContainerRef}>
      <div className="image-container" ref={headerImageRef} >
        <img className="header-image"
          src={computerScreenImage}
          alt="Computer Screen"
          onLoad={handleImageLoad}/>
        <button onClick={redirectToURL} className='cta-button'>Get LearnFast Free</button>
      </div>
    </div>
  );
}

import React from 'react';
import './ProductPage.css';
import './savingslots.css';
import Header from './Header';

import Footer from './Footer';
import computerScreenImage from './in-sotre.png';
import SavingSlotsProductCard from './SavingSlotsProductCard';

export default function SavingSlots() {
  return (
    <div className="product-page">
      <Header
        pageHeading={<>Saving Slots</>}
        rightContent={<SavingSlotsProductCard />}
      />

      <div className="saving-slots-info-container">
        <p
          style={{
            color: 'hsl(0deg, 0%, 22.05%)',
            marginBlockStart: 0,
            marginBlockEnd: 0,
          }}
        >
          (Deprecated)
        </p>
        <div className="product-info">
          <h2>
            Bring gamified customer retention into your retail environment.
          </h2>
          <div className="in-store-row">
            <div>
              <div className="how-it-works-underline how-it-works-underline-bold steps">
                How it works:
              </div>
              <br />
              <br />

              <div className="steps">
                <span style={{ fontSize: '.75em' }}>🎲 </span> Create multiple
                deals and discounts for customers to win. You choose their odds
                of winning.
              </div>
              <br />
              <div className="steps">
                <span style={{ fontSize: '.75em' }}>🎴 </span>
                Print and display your store's QR code so customers can play.
              </div>
              <div className="steps">
                <span style={{ fontSize: '.75em' }}>⚙️ </span>
                Update and optimize your deals hook customers and keep them
                coming back.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <img
                style={{ margin: '.5em auto' }}
                className="saving-slots-image"
                src={computerScreenImage}
                alt="Computer Screen"
              />
              <p style={{ margin: '0em auto' }}>Saving Slots in-store</p>
            </div>
          </div>
        </div>
      </div>
      <Footer privacyPolicy={{ url: null, AppName: 'Saving Slots' }} />
    </div>
  );
}

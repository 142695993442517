import React, { useEffect, useState, useRef } from 'react';
import './ProductPage.css';
import Header from './Header';
import LearnFastProductCard from './LearnFastProductCard';
import ExamplePopUp from './ExamplePopUp';
import mouseicon from './mouseicon.png';
import LearnFastExamples from './LearnFastExamples';
import Footer from './Footer';

const LearnFastProductPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  function redirectToURL() {
    const url =
      'https://chrome.google.com/webstore/detail/learnfast/edakpcmkahenpalocbmibgpppajlnpdb';
    window.open(url, '_blank');
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const closePopup = () => {
    setIsOpen(false);
  };

  function blinkLight() {
    const circleBehind = document.querySelector('.circle-behind');
    let blinkCount = 0;
    const blinkInterval = setInterval(() => {
      // Toggle the circle's visibility
      circleBehind.style.visibility =
        circleBehind.style.visibility === 'hidden' ? 'visible' : 'hidden';
      blinkCount++;

      if (blinkCount >= 4 && circleBehind.style.visibility === 'hidden') {
        clearInterval(blinkInterval);
      }
    }, 75);
  }

  useEffect(() => {
    const blinkInterval = setInterval(blinkLight, 4500);

    return () => {
      clearInterval(blinkInterval); // Clear the interval when the component is unmounted
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        closePopup();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  return (
    <div className="product-page">
      <Header
        pageHeading={
          <>
            <span
              style={{
                color: 'black',
                mixBlendMode: 'normal',
                opacity: '100%',
              }}
            >
              LearnFast
            </span>
            <br />
            Chrome <br />
            Extension
          </>
        }
        rightContent={<LearnFastProductCard />}
      />

      <div className="product-info">
        <h2>
          Making even the{' '}
          <span className="how-it-works-underline">hardest</span> topics{' '}
          <span className="how-it-works-underline">easy to learn</span> by
          simplifying complex language.
        </h2>

        <div className="how-it-works-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            How it works:
          </div>
          <br />
          <br />

          <div className="steps">
            <span style={{ fontSize: '.75em' }}>💻 </span>Install the extension,
            then highlight any text to activate it
            <div className="highlight highlight1"></div>
          </div>
          <br />

          <div className="steps product-info-row how-it-works-popup-row">
            <div>
              <span style={{ fontSize: '.75em' }}>🖱️ </span>Click the icon to
              open the popup
            </div>
            <div className="how-it-works-popup-container">
              <div
                onClick={() => {
                  togglePopup();
                }}
                className="collapsed-icon"
              >
                {' '}
                {isOpen && <ExamplePopUp popupRef={popupRef} />}
              </div>
              <div className="circle-behind"></div>
            </div>
          </div>
          <br />

          <div
            id={'step3'}
            style={{ width: '100%', position: 'relative' }}
            className="steps"
          >
            <span style={{ fontSize: '.75em' }}>🧠 </span>
            Simplify the text with the click of a button
          </div>
          <br />

          <div
            style={{
              width: '100%',
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
            }}
            className="steps"
          >
            <button onClick={redirectToURL} className="simplify-submit-button">
              Simplify<img className="mouse-icon" src={mouseicon}></img>
            </button>
            <div className="small-text">
              This one just takes you to the chrome store
            </div>
          </div>
        </div>
        <br />
        <LearnFastExamples />
        <br />
        <div className="bottom-cta">
          <h3 style={{ paddingRight: '1em' }}>
            That's it.{' '}
            <span className="how-it-works-underline">
              Install the free version
            </span>{' '}
            and start learning at <em>warp speed</em>.
          </h3>
          <button onClick={redirectToURL} className="cta-button">
            Get LearnFast Free
          </button>
        </div>
        <div className="tech-used-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Tech Used:
          </div>
          <div className="steps product-info-row how-it-works-popup-row tech-used-row-container">
            <div>
              <span style={{ fontWeight: 400 }}>Front End: </span> Vanilla JS,
              React, CSS, Chrome MV3
            </div>
            <div>
              <span style={{ fontWeight: 400 }}>Back End: </span>Firebase,
              Stripe, Node JS, OpenAi API, AWS:{' '}
              <span style={{ fontSize: '.75em' }}>API Gateway, Lambda</span>
            </div>
          </div>
        </div>

        <iframe
          style={{
            borderRadius: '.5em',
            boxShadow:
              '0 4px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)',
          }}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/98KFY9BVawc?si=uL5pdcKsUrn4LWB8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <Footer
          privacyPolicy={{
            url: '/learn-fast-privacy-policy',
            AppName: 'LearnFast',
          }}
        />
      </div>
    </div>
  );
};

export default LearnFastProductPage;

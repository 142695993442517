import React, { useEffect, useRef } from 'react'
import './LearnFastExamples.css'
import Header from './Header';
import Footer from './Footer';

export default function LearnPrivacyPolicy() {

    return (<>
        <Header 
        pageHeading={<>
          <span style={{color:'black', mixBlendMode:'normal',opacity:'100%',width:'90%'}}>LearnFast</span>
          <br/> Privacy<br/> Policy
      </>} />
            <div style={{ width: '85%', color:'white', justifyContent: 'center', marginLeft:'auto', marginRight:'auto',marginTop:'1em' }}>

                    <div className="steps ">
                        <div className='how-it-works-underline how-it-works-underline-bold'> Privacy Policy:</div>
                    </div>
                    <div className='example-row'>
                        This Privacy Policy explains how we collect, use, and safeguard the personal information of users who install and use LearnFast ("the Extension"). By installing and using the Extension, you agree to the terms and practices described in this Privacy Policy.
                        <br/>
                        1. Information We Collect
                        <br/>
                        1.1 Personal Information:
                        We do not collect any personal information that can directly identify you, such as your name, address, or contact details unless you voluntarily provide such information to us through the Extension.
                        <br/>
                        1.2 Non-Personal Information:
                        We may collect non-personal information that does not directly identify you, such as usage data, analytics, and other technical information related to your use of the Extension. This information is collected to enhance and improve the functionality and performance of the Extension.
                        <br/>
                        <br/>
                        2. Use of Information
                        <br/>
                        2.1 Personal Information:
                        If you voluntarily provide us with personal information, we may use it for the following purposes:
                        <br/>
                        To respond to your inquiries, feedback, or support requests.
                        To provide you with updates, notifications, and information related to the Extension.
                        To improve our services and enhance user experience.
                        <br/>
                        2.2 Non-Personal Information:
                        We may use non-personal information for statistical purposes, to analyze trends, and to improve the Extension's performance and functionality.
                        <br/>
                        <br/>
                        3. Data Sharing and Disclosure
                        <br/>
                        We do not sell, trade, or transfer your personal information to third parties without your consent unless required by law or as necessary to protect our legal rights.
                        <br/>
                        <br/>
                        4. Third-Party Services and Links
                        <br/>
                        The Extension may include links to third-party websites, services, or resources that are not under our control. We encourage you to review the privacy policies of these third-party services, as we have no control over and assume no responsibility for their practices or content.
                        <br/>
                        <br/>
                        5. Security
                        <br/>
                        We take reasonable precautions and employ industry-standard practices to protect the personal information collected through the Extension. However, please be aware that no method of transmission or storage over the internet is entirely secure, and we cannot guarantee absolute security.
                        <br/>
                        <br/>
                        6. Updates to the Privacy Policy
                        <br/>
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We encourage you to review this Privacy Policy periodically for any updates.
                    </div>
                    <Footer/>
            </div>
            </>)
}

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import ReleaseProductCard from './ReleaseProductCard';

export default function Release() {
  return (
    <div className="product-page">
      <Header
        pageHeading={
          <>
            <span
              style={{
                color: 'black',
                mixBlendMode: 'normal',
                opacity: '100%',
              }}
            >
              Release Client Site
            </span>
          </>
        }
        rightContent={<ReleaseProductCard />}
      />
      <div className="product-info">
        <h2>
          Landing page and booking page for yoga and movement therapy
          practioner.
        </h2>
        <div style={{ minWidth: '85%' }} className="how-it-works-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Site Features:
          </div>
          <br />
          <br />

          <div className="steps">
            <span style={{ fontSize: '.75em' }}>🎬 </span>Custom animations,
            icons, and graphics
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>💡 </span>Branding and design
            ideation
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>🛒 </span>Checkout with Stripe
          </div>
        </div>
        <div className="tech-used-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Tech Used:
          </div>
          <div className="steps product-info-row how-it-works-popup-row tech-used-row-container">
            <div>
              <span style={{ fontWeight: 400 }}>Front End: </span> React, CSS,
              Tailwind
            </div>
          </div>
        </div>
      </div>
      <Footer
        privacyPolicy={{
          url: null,
          AppName: 'Release With Vida',
        }}
      />
    </div>
  );
}

import React from 'react';
import Header from './Header';
import Footer from './Footer';
import PhotoboothProductCard from './PhotoboothProductCard';

export default function PhotoBooth() {
  return (
    <div className="product-page">
      <Header
        pageHeading={
          <>
            <span
              style={{
                color: 'black',
                mixBlendMode: 'normal',
                opacity: '100%',
                lineHeight: '0em',
              }}
            >
              <span
                style={{
                  fontSize: '.35em',
                  lineHeight: '.9em',
                  display: 'inline-block',
                  marginLeft: '.1em',
                }}
              >
                AWS Community Day <br />
              </span>{' '}
              Photo Booth
            </span>
          </>
        }
        rightContent={<PhotoboothProductCard />}
      />
      <div className="product-info">
        <h2>
          Web app that applies effects to attendees' selfies or uploaded photos
          in alignment with the conference's theme.
        </h2>
        <div style={{ minWidth: '85%' }} className="how-it-works-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Web App Features:
          </div>
          <br />
          <br />

          <div className="steps">
            <span style={{ fontSize: '.75em' }}>☁️ </span>Cloud-based image
            processing
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>🎨 </span>Custom animations,
            icons, and graphics
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>💻 </span>Minimal, intuitive UI
            on desktop and mobile
          </div>
        </div>
        <div className="tech-used-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Tech Used:
          </div>
          <div className="steps product-info-row how-it-works-popup-row tech-used-row-container">
            <div>
              <span style={{ fontWeight: 400 }}>Front End: </span> React, CSS,
              Tailwind
            </div>
            <div>
              <span style={{ fontWeight: 400 }}>Back End: </span>Python, AWS:{' '}
              <span style={{ fontSize: '.75em' }}>
                API Gateway, Lambda, S3, EC2
              </span>
            </div>
          </div>
        </div>
      </div>

      <Footer
        privacyPolicy={{
          url: null,
          AppName: 'AWS Community Day Photo Booth',
        }}
      />
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import dashComputerScreenImage from './slot-app.gif';
import './LearnFastProductCard.css';

export default function PsuedoDashProductCard({
  contentHeight,
  setContentHeight,
}) {
  const productCardContainerRef = useRef(null);
  const headerImageRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    adjustTitleBoxHeight();
    window.addEventListener('resize', adjustTitleBoxHeight);
    return () => {
      window.removeEventListener('resize', adjustTitleBoxHeight);
    };
  }, [imageLoaded]);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const adjustTitleBoxHeight = () => {
    const productCardContainer = productCardContainerRef.current;
    const headerImage = headerImageRef.current;

    if (productCardContainer && headerImage && imageLoaded) {
      // Reset the height of the title box
      productCardContainer.style.height = 'auto';

      // Set the height of the title box to the height of the image
      const imageHeight = headerImage.offsetHeight;
      const viewportWidth = window.innerWidth;
      const scalingFactor =
        viewportWidth > 1055
          ? Math.pow(viewportWidth / 1300, 0.05)
          : Math.pow(viewportWidth / 1300, 0.1);

      // Decrease the height of the title box relative to the image height and scaling factor
      const inverseScalingMultiplier = 1 + (1 - scalingFactor) * 2;
      console.log(scalingFactor);
      if (viewportWidth >= 610) {
        productCardContainer.style.height = `${
          imageHeight - (125 - inverseScalingMultiplier * 50)
        }px`;
      } else {
        productCardContainer.style.height = `${
          imageHeight - (125 - inverseScalingMultiplier * 65)
        }px`;
      }

      if (contentHeight && imageHeight - 125 > contentHeight) {
        setContentHeight(imageHeight - 125);
      }
    }
  };
  function redirectToURL() {
    const url = 'https://master.d35yssiiy8qdww.amplifyapp.com/';
    window.open(url, '_blank');
  }

  return (
    <div
      className="product-card-container ss-card"
      ref={productCardContainerRef}
    >
      <div className="saving-slot-image-container" ref={headerImageRef}>
        <img
          className="header-image"
          src={dashComputerScreenImage}
          alt="Computer Screen"
          onLoad={handleImageLoad}
        />
      </div>
      <div className="phone-shadow"></div>
    </div>
  );
}

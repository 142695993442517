import React from 'react';
import './ExamplePopUp.css';

export default function ExamplePopUp({ popupRef }) {
  function handleClick() {
    const url =
      'https://chrome.google.com/webstore/detail/learnfast/edakpcmkahenpalocbmibgpppajlnpdb';
    window.open(url, '_blank');
  }
  return (
    <div ref={popupRef} className="popup">
      <div className="popup-content">
        <p className="popup-message">
          This button is not active,{' '}
          <a onClick={() => handleClick()}>get the free chrome extension</a> to
          see it for real.
        </p>
      </div>
    </div>
  );
}

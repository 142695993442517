import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CimaVistaProductCard from './CimaVistaProductCard';

export default function CimaVista() {
  return (
    <div className="product-page">
      <Header
        pageHeading={
          <>
            <span
              style={{
                color: 'black',
                mixBlendMode: 'normal',
                opacity: '100%',
              }}
            >
              Cima Vista Client Site
            </span>
          </>
        }
        rightContent={<CimaVistaProductCard />}
      />
      <div className="product-info">
        <h2>Travel & tourism site focused on Central America.</h2>
        <div style={{ minWidth: '85%' }} className="how-it-works-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Site Features:
          </div>
          <br />
          <br />

          <div className="steps">
            <span style={{ fontSize: '.75em' }}>💻 </span>Sanity content
            management system so the client can update the site easily
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>🔎 </span>Simple search bar
          </div>
          <div className="steps">
            <span style={{ fontSize: '.75em' }}>📱 </span>Mobile-friendly
            responsive design
          </div>
        </div>
        <div className="tech-used-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Tech Used:
          </div>
          <div className="steps product-info-row how-it-works-popup-row tech-used-row-container">
            <div>
              <span style={{ fontWeight: 400 }}>Front End: </span> Next.js, CSS,
              Tailwind
            </div>
            <div>
              <span style={{ fontWeight: 400 }}>Back End: </span>Sanity
            </div>
          </div>
        </div>
      </div>
      <Footer
        privacyPolicy={{
          url: null,
          AppName: 'Cima Vista',
        }}
      />
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';

export default function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);
  return (
    <nav ref={navRef}>
      <div className="nav-inner-container">
        <div>
          <Link to="/" className={`link `}>
            Goldwasser
          </Link>
        </div>

        <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          ☰
        </div>
        <div className={`links ${isOpen ? 'open' : ''}`}>
          <Link
            to="/psuedo-training-dashboard"
            className={`link header-link ${isOpen ? 'open' : ''}`}
          >
            Psuedo-Training Dash
          </Link>
          <Link
            to="/learn-fast"
            className={`link header-link ${isOpen ? 'open' : ''}`}
          >
            LearnFast
          </Link>
          <Link
            to="/saving-slots"
            className={`link header-link ${isOpen ? 'open' : ''}`}
          >
            Saving Slots
          </Link>
          <Link
            to="/"
            className={`link header-link header-link-home ${
              isOpen ? 'open' : ''
            }`}
          >
            Home
          </Link>
        </div>
      </div>
    </nav>
  );
}

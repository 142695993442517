 import React, {useEffect,useRef} from 'react'
 import './LearnFastExamples.css'

export default function LearnFastExamples() {
    const gridboxRef = useRef([]);


    const runAnimation = () => {
      
        const getRandomColor = (shape) => {
            const colors = [
                { hueMin: 320, hueMax: 340 },   // Color 1
                { hueMin: 21, hueMax: 41 }, // red
                { hueMin: 229, hueMax: 246 },  // blue
                { hueMin: 46, hueMax: 32 },  // blue
            ];

            let colorIndex;
            if (Math.random() < 0.5) {
                colorIndex = 0; // Color 1 is picked 25% of the time
            }
            else if (Math.random() > 0.87) {
                colorIndex = colors.length-1
            }
            else {
                colorIndex = Math.floor(Math.random() * (colors.length - 1)) + 1;
            }

            return colors[colorIndex];
        }

        function getRandomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }


        // Function to create a random shape
        const createRandomShape = (container) => {
            const shape = document.createElement('div');
            shape.classList.add('shape');
            shape.classList.add('static');

            // Set random position and size for the shape
            const left = getRandomNumber(-100, 100);
            const top = getRandomNumber(-100, 90);
            const size = getRandomNumber(250, 550);
            shape.style.left = `${left}%`;
            shape.style.top = `${top}%`;
            shape.style.width = `${size}px`;
            shape.style.height = `${size}px`;
            const originPoint = `${getRandomNumber(50, 100)}% ${getRandomNumber(50, 100)}%`
            shape.style.transformOrigin = originPoint
            shape.style.filter = 'blur(25px)'
            shape.style.opacity = '.25'

            // Set random background color for the shape 264, 71%, 51%
            const currentColor = getRandomColor(shape)
            const hue = getRandomNumber(currentColor.hueMin, currentColor.hueMax); // Set the range of purple hues
            const saturation = getRandomNumber(69, 73); // Set the range of saturation
            const lightness = getRandomNumber(6, 56); // Set the range of lightness
            const gradient = `radial-gradient(hsl(${hue},${saturation}%, ${lightness}%), hsl(357, ${saturation}%, ${51}%))`;
            shape.style.backgroundImage = gradient


            // Append the shape to the gradient div
            container.appendChild(shape);
            setTimeout(() => {
                shape.classList.add('show');
            }, 500); }

     
        gridboxRef.current.forEach((gridRef) => {
                createRandomShape(gridRef);
            });

     ;}


    useEffect(() => {
        runAnimation();
      }, []);

  return (
    <>
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
        
        <div ref={(el) => (gridboxRef.current[0] = el)} className="example-item static">
            <div className="steps ">
                <div className='how-it-works-underline how-it-works-underline-bold'> Example:</div>  
            </div>
            <div className='example-row'>
            <div style={{paddingRight:'1em'}}>
            <h3 className='h3-left'>Original Text</h3>
            The patient presented with a subacute exacerbation of chronic obstructive pulmonary disease characterized by dyspnea, cyanosis, and bilateral crackles on auscultation, necessitating immediate initiation of bronchodilator therapy and oxygen supplementation.
            </div>
            
            <div style={{paddingRight:'1em'}}>
            <h3 className='h3-left'>Simplified Text</h3>
            The patient experienced a worsening of their long-term breathing condition called chronic obstructive pulmonary disease. They had difficulty breathing, a bluish tint to their skin, and abnormal lung sounds on examination. As a result, they needed immediate treatment with medications to open up their airways and extra oxygen to help them breathe better.
            </div>
            </div>
            </div>
        </div>
    </>
  )
}

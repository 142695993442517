import React, { useEffect, useState, useRef } from 'react';
import './ProductPage.css';
import Header from './Header';

import Footer from './Footer';
import PsuedoDashProductCard from './PsuedoDashProductCard';

const LearnFastProductPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  function redirectToURL() {
    const url = 'https://master.d35yssiiy8qdww.amplifyapp.com/';
    window.open(url, '_blank');
  }

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div className="product-page">
      <Header
        pageHeading={
          <>
            <span
              style={{
                color: 'black',
                mixBlendMode: 'normal',
                opacity: '100%',
              }}
            >
              Psuedo-Training
            </span>
            <br />
            Dashboard
          </>
        }
        rightContent={<PsuedoDashProductCard />}
      />

      <div className="product-info">
        <h2>
          Use your oganization's data with ChatGPT, without the time, money, and
          complexity of fine tuning.
        </h2>

        <div className="how-it-works-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            How it works:
          </div>
          <br />
          <br />

          <div className="steps">
            <span style={{ fontSize: '.75em' }}>📁 </span>Upload a text file,
            PDF, or CSV.{' '}
            <div className="small-text">
              {'CSVs are an experimental feature'}
            </div>
          </div>
          <br />

          <div className="steps product-info-row how-it-works-popup-row">
            <div>
              <span style={{ fontSize: '.75em' }}>🧠 </span>Convert one or
              multiple files into a knowledge cluster.
            </div>
          </div>
          <br />

          <div
            style={{ width: '100%', position: 'relative' }}
            className="steps"
          >
            <span style={{ fontSize: '.75em' }}>💬 </span>Use your knowledge
            cluster to answer questions, generate content, and more.
          </div>
          <br />
        </div>

        <div className="tech-used-container">
          <div className="how-it-works-underline how-it-works-underline-bold steps">
            Tech Used:
          </div>
          <div className="steps product-info-row how-it-works-popup-row tech-used-row-container">
            <div>
              <span style={{ fontWeight: 400 }}>Front End: </span> React, CSS
            </div>
            <div>
              <span style={{ fontWeight: 400 }}>Back End: </span>Python, Flask,
              AWS:{' '}
              <span style={{ fontSize: '.75em' }}>
                API Gateway, Cognito, DynamoDB, Lambda, S3, EC2
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer privacyPolicy={{ url: null, AppName: 'Psuedo Training Dash' }} />
    </div>
  );
};

export default LearnFastProductPage;

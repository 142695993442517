import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './HomePage';
import LearnFastProductPage from './LearnFastProductPage';
import LearnPrivacyPolicy from './LearnFastPrivacyPolicy';
import PsuedoTrainingDash from './PsuedoTrainingDash';
import SavingSlots from './SavingSlots';
import StudioPage from './StudioPage';
import Release from './Release';
import CimaVista from './CimaVista';
import PhotoBooth from './PhotoBooth';
import ScrollToTop from './ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/studio" element={<StudioPage />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/photo-booth" element={<PhotoBooth />} />
        <Route path="/release" element={<Release />} />
        <Route path="/cima-vista" element={<CimaVista />} />
        <Route path="/learn-fast" element={<LearnFastProductPage />} />
        <Route
          path="/learn-fast-privacy-policy"
          element={<LearnPrivacyPolicy />}
        />
        <Route
          path="/psuedo-training-dashboard"
          element={<PsuedoTrainingDash />}
        />
        <Route path="/saving-slots" element={<SavingSlots />} />
      </Routes>
    </Router>
  );
}

export default App;

import React, {useEffect} from 'react'

export default function HeaderAnimation() {
    useEffect(() => {
        const gradientDiv = document.querySelector('.gradient');

        const getRandomColor = (shape) => {
            const colors = [
                { hueMin: 320, hueMax: 340 },   // Color 1
                { hueMin: 21, hueMax: 41 }, // red
                { hueMin: 229, hueMax: 246 },  // blue
                { hueMin: 46, hueMax: 32 },  // blue
            ];

            let colorIndex;
            if (Math.random() < 0.5) {
                colorIndex = 0; // Color 1 is picked 25% of the time
            }
            else if (Math.random() > 0.87) {
                colorIndex = colors.length-1
                shape.size = 100
            }
            else {
                colorIndex = Math.floor(Math.random() * (colors.length - 1)) + 1;
            }

            return colors[colorIndex];
        }

        function getRandomNumber(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }


        // Function to create a random shape
        const createRandomShape = () => {
            const shape = document.createElement('div');
            shape.classList.add('shape');

            // Set random position and size for the shape
            const left = getRandomNumber(-100, 100);
            const top = getRandomNumber(-100, 90);
            const size = getRandomNumber(250, 550);
            shape.style.left = `${left}%`;
            shape.style.top = `${top}%`;
            shape.style.width = `${size}px`;
            shape.style.height = `${size}px`;
            const originPoint = `${getRandomNumber(50, 100)}% ${getRandomNumber(50, 100)}%`
            shape.style.transformOrigin = originPoint

            // Set random background color for the shape 264, 71%, 51%
            const currentColor = getRandomColor(shape)
            const hue = getRandomNumber(currentColor.hueMin, currentColor.hueMax); // Set the range of purple hues
            const saturation = getRandomNumber(69, 73); // Set the range of saturation
            const lightness = getRandomNumber(56, 66); // Set the range of lightness
            const gradient = `radial-gradient(hsl(${hue},${saturation}%, ${lightness}%), hsl(357, ${saturation}%, ${51}%))`;
            shape.style.backgroundImage = gradient


            // Append the shape to the gradient div
            gradientDiv.appendChild(shape);
            setTimeout(() => {
                shape.classList.add('show');
            }, 500);

            // Remove the shape after a certain duration
            setTimeout(() => {
                shape.classList.remove('show');
                setTimeout(() => {
                    gradientDiv.removeChild(shape)
                }, 5000);
            }, 5000);
        }
        for (let i = 0; i < 7; i++) {
            createRandomShape();
        }
        // Create random shapes periodically
        const intervalId = setInterval(createRandomShape, 250);

        // Clean up the interval on component unmount
        return () => {
            clearInterval(intervalId);
        };
    }, []);
  return (
    <div className='gradient'></div>
  )
}

import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = (props) => {
  const { privacyPolicy } = props;
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <div className="footer-logo">
            <span className="logo-text">
              Goldwasser | {privacyPolicy && privacyPolicy.AppName}
            </span>
          </div>
          {
            //<nav className="footer-nav">
            //<ul>
            //<li><a href="/">Home</a></li>
            //<li><a href="/about">About</a></li>
            //<li><a href="/services">Services</a></li>
            //<li><a href="/contact">Contact</a></li>
            //</ul>
            //</nav>
          }
        </div>
        <div className="footer-bottom">
          <p>
            &copy; {new Date().getFullYear()} Zak Goldwasser. All rights
            reserved.
          </p>
          {privacyPolicy?.url && (
            <Link to="/learn-fast-privacy-policy" className="link">
              <p style={{ color: 'hsl(0deg, 0%, 22.05%)' }}>
                {privacyPolicy.AppName} Privacy Policy
              </p>
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

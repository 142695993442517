import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import HomePageHeaderCard from './HomePageHeaderCard';

export default function HomePage() {
  return (
    <div className="homepage">
      <Header pageHeading="Zak Goldwasser" />
      <main className="main-content">
        {/*<p style={{ color: 'white', fontSize: '1.2rem', marginBottom: '2rem' }}>
          Currently adding features to the{' '}
          <Link
            to="/psuedo-training-dashboard"
            style={{
              color: 'rgb(255,255,255)',
              borderBottom: '.5px white solid',
            }}
          >
            Psuedo-Training Dashboard
          </Link>
          .
          </p>*/}
        <div className="bio-container">
          <hr
            style={{
              border: '1px solid rgba(0,0,0,.15)',
              width: '25%',
              margin: 'auto',
            }}
          ></hr>

          <h3>
            <img
              style={{
                maxWidth: '50px',
                opacity: '20%',
                marginRight: '.5em',
              }}
              src="./slide-iconAsset4.png"
            />
            I am a self-taught full-stack developer with the majority of my
            experience in front-end development. I have built landing pages,
            event applications, and dynamic sites that can be updated via a CMS
            for clients. On my own, I have constructed both the front and back
            ends of several products, aiming to refine my skills with the goal
            of building a top-tier SAAS.{' '}
            <img
              style={{
                right: 0,
                transform: 'translate(0,35%) rotate(180deg)',
                maxWidth: '50px',
                opacity: '20%',
                marginLeft: '.5em',
              }}
              src="./slide-iconAsset4.png"
            />
          </h3>

          <hr
            style={{
              border: '1px solid rgba(0,0,0,.15)',
              width: '25%',
              margin: 'auto',
            }}
          ></hr>
          <h4 style={{ width: '75%', margin: '1em auto' }}>
            Currently, I am seeking an organization where working hard and
            driving results not only benefit the business but also accelerate my
            learning.
          </h4>
        </div>
        <h1
          style={{
            marginLeft: '.75em',
            color: 'hsla(0deg, 0%, 22.05%, 95%)',
            textAlign: 'left',
          }}
        >
          Freelance Projects:
        </h1>
        <div className="card-row">
          <div className="card-container">
            <Link to="/photo-booth">
              <div className="card">
                <span className="card-name">AWS Community Day Photo Booth</span>
                <div className="card-header">
                  <div>Client Web App</div>
                  <div>React, Tailwind, AWS</div>
                </div>

                <div>
                  <p>
                    Web app that applies effects to attendees' selfies or
                    uploaded photos in alignment with the conference's theme.
                  </p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
          <div className="card-container">
            <Link to="/release">
              <div className="card">
                <span className="card-name">Release</span>
                <div className="card-header">
                  <div>Client Site</div>
                  <div>React, Tailwind</div>
                </div>

                <div>
                  <p>
                    Landing page and booking page for yoga and movement therapy
                    practioner. Included custom animations and graphics.
                  </p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
          <div className="card-container">
            <Link to="/cima-vista">
              <div className="card">
                <span className="card-name">Cima Vista</span>
                <div className="card-header">
                  <div>Client Site</div>
                  <div>Sanity, Nextjs, Tailwind</div>
                </div>

                <div>
                  <p>
                    Travel & tourist site focused on Central America. Includes
                    Sanity content management system so client can easily update
                    the site without the need for additional code.
                  </p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
        </div>
        <h1
          style={{
            marginLeft: '.75em',
            color: 'hsla(0deg, 0%, 22.05%, 95%)',
            textAlign: 'left',
          }}
        >
          Products:
        </h1>
        <div className="card-row">
          <div className="card-container">
            <Link to="/psuedo-training-dashboard">
              <div className="card">
                <span className="card-name">Psuedo-Training Dashboard </span>
                <div className="card-header">
                  <div>Web App</div>
                  <div>B2B</div>
                </div>

                <div>
                  <p>
                    Use your oganization's data with ChatGPT, without the time,
                    money, and complexity of fine tuning.
                  </p>
                  <p>React, CSS, OpenAI API, Multiple AWS Services</p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
          <div className="card-container">
            <Link style={{}} to="/learn-fast">
              <div className="card">
                <span className="card-name">LearnFast</span>
                <div className="card-header">
                  <div>Chrome Extension</div>
                  <div>B2C</div>
                </div>
                <div>
                  <p>
                    Make even the hardest topics easy to learn by simplifying
                    complex language.
                  </p>
                  <p>
                    React, CSS, Chrome Extension SDK Mv3, AWS Lambda, OpenAI API
                  </p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
          <div className="card-container">
            <Link to="/saving-slots">
              <div className="card">
                <span className="card-name">Saving Slots</span>
                <div className="card-header">
                  <div>Web App</div>
                  <div>B2B</div>
                </div>
                <div>
                  <p>
                    Bring gamified customer retention into your retail
                    environment.
                  </p>
                  <p>React, CSS, Firestore</p>
                </div>
              </div>
            </Link>
            <div className="card-glow"></div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
